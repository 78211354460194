.progress-container {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
  :global .progress-title {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    color: #777e8b;
    .progress-quotient {
      font-weight: 600;
    }
  }
  :global .progress-bar-container {
    width: 235px;
    padding-top: 0.5rem;
    .progress-bar {
      height: 10px;
      background-color: #f1f1f5;
      .progress-filled {
        height: 10px;
        transition: 0.5s;
      }
    }
  }
}
