@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.ChooseProject {
  padding: rem(16px);
  :global .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  :global button {
    width: rem(116px);
  }
}
