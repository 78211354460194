@import "src/styles/_tools_to_rem.scss";

.stepperContainer {
  margin-top: -5rem;
  margin-bottom: 1.5rem;
  width: 70vw;
  padding: 0 2rem;
  border-radius: 0.8rem;
  background: #fff;
  flex: 1;
  :global .change-amount-container {
    width: 550px;
    margin-bottom: 1rem;
    padding: 0 1rem;
    .amount-input-container {
      min-height: 7vh;
    }
  }
  :global .ant-steps-item {
    position: relative;
    .ant-steps-item-content .ant-steps-item-title {
      position: initial;
      .ant-steps-item-subtitle {
        position: absolute;
        right: 1rem;
        padding: 0 1rem;
      }
    }
  }
  :global .ant-steps-item-active .ant-steps-item-subtitle {
    display: none;
  }
  :global #paymentContainer {
    max-width: unset;
    width: unset;
  }
}

@media only screen and (max-width: 680px) {
  .stepperContainer {
    width: initial;
    padding: initial;
    margin: initial;
  }
}
