.redsys-iframe-container {
  :global .cardinfo-card-number {
    display: flex;
    align-items: center;
    #card-number {
      width: 300px;
      height: 90px;
    }
    #ccnumber {
      display: flex;
      align-items: center;
      width: 326px;
      margin: 16px 16px 16px 0;
    }
    .card-icons {
      display: flex;
      justify-content: space-between;
      width: 130px;
    }
  }
  :global label {
    color: #777e8b;
    padding-left: 0.8rem;
  }
  :global .expiry-date {
    display: flex;
    width: 530px;
    height: 110px;
  }
  :global #redsys-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    cursor: pointer;
    iframe {
      width: unset !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .redsys-iframe-container {
    :global .exp-cvc,
    :global .cardinfo-card-number {
      flex-direction: column;
      .card-icons {
        display: none;
      }
      width: 300px;
      align-items: center;
      & > div {
        margin: 16px 16px 16px 0;
        width: 97% !important;
      }
    }
  }
}
