@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.baseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: rem(180px);
  height: rem(48px);
  :global button {
    cursor: pointer;
    width: 16rem;
    height: rem(50px);
    border-radius: rem(24px);
    font-size: rem(16px);
    font-weight: 500;
    border: $primary-color solid rem(1px);
    flex-direction: row;
    color: $white-color;
    background-color: $primary-color;
    transition: all 300ms;
    &:disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      border: rem(1px) solid $_disabled_blue;
      color: $white-color;
      background-color: $_disabled_blue;
      filter: opacity(50%);
      cursor: not-allowed;
    }
    &:hover:enabled,
    &:active:enabled {
      filter: contrast(200%);
    }
    :global .btn-icon {
      margin-left: rem(5px);
    }
  }
}
@media only screen and (max-width: 680px) {
  .baseButton {
    :global button {
      width: rem(180px);
      height: rem(40px);
      font-size: 0.75rem;
      margin: rem(10px);
    }
  }
}
