@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.AmountView {
  padding: 0 5vw;
  :global .amount-layout {
    display: flex;
    // padding: 0 5vw;
    align-items: center;
    justify-content: space-between;
    height: 55vh;

    .select-amount-title {
      font-size: rem(18px);
      font-weight: 600;
    }
    .carbon-tones-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      min-height: rem(200px);
      background-color: $gray-light-color;
      width: rem(520px);
      border-radius: rem(8px);
      box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.08), 0px 16px 30px rgba(0, 0, 0, 0.08),
        0px 10px 18px rgba(0, 0, 0, 0.08), 0px 5px 10px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.08),
        0px 1px 2px rgba(0, 0, 0, 0.08);
    }
  }
}

@media only screen and (max-width: 680px) {
  .AmountView {
    :global .amount-layout {
      flex-direction: column;
      height: unset;
      padding: 2rem 1rem;
      .offset-title {
        font-size: 1.5rem;
        max-width: unset;
      }
      .carbon-tones-container {
        width: unset;
        min-height: rem(320px);
      }
    }
  }
}
