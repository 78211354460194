@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.PersonalInfo {
  margin-left: rem(25px);
  :global .personal-form {
    .description-container {
      display: flex;
      align-items: self-start;
      flex-direction: column;
      .description {
        margin-left: rem(28px);
      }
    }
    .customer-type-select {
      margin: 1.2rem 0.5rem;
    }
    .form-container {
      border-radius: rem(10px);
      margin-right: rem(25px);
      padding: 0.2rem;
      display: grid;
      grid-template-columns: 50% 50%;

      .inputs {
        display: flex;
        .ant-row {
          width: -webkit-fill-available;
        }
        input {
          min-height: 48px;
          border: 1px solid rgba(0, 0, 0, 0.06);
          border-radius: 8px;
        }
        .selector {
          width: rem(250px);
        }
      }
    }
    .offset-reason-select,
    .country-select {
      width: -webkit-fill-available;
      margin-right: rem(25px);
      margin-bottom: 1rem;
      max-height: 50px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 8px;

      .ant-select-selector {
        min-height: 48px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        align-items: center;
      }
    }
    input[type="checkbox"] {
      position: absolute;
      &:after {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .PersonalInfo {
    margin-left: unset;
    :global .personal-form {
      .customer-type-select,
      .offset-reason-select,
      .country-select {
        margin-left: 0.7rem;
      }
      .description-container .description {
        margin-bottom: unset;
        margin-left: 0.7rem;
      }
      .form-container {
        margin: 0;
        display: block;
        .inputs {
          margin: 0.5rem;
        }
      }
    }
  }
}
