@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.AmountView {
  flex: 1;
  :global .amount-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 55vh;
    .select-amount-title {
      font-size: rem(18px);
      font-weight: 600;
    }
    .carbon-tones-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 48px;
      align-items: center;
      width: 100%;
      height: 100%;
      .select-amount-title {
        width: 100%;
        text-align: center;
        padding: 1.2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
      .amount-input-container {
        padding: 3rem;
        flex-direction: column;
        .TextComponent:not(.ModalComponent *) {
          width: 100%;
          padding-top: 1rem;
          font-weight: 600;
        }
        .ButtonComponent:not(.ModalComponent *) {
          width: 100%;
          font-weight: 600;
        }
      }
    }
    .trees-saved-container {
      width: 100%;
      height: 220px;
      background: url("/images/trees-bg.svg");
      border-radius: 16px;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .blurry-trees-container {
        max-width: 95%;
        background: rgba(255, 255, 255, 0.28);
        border-radius: 16px;
        width: 366px;
        min-height: 70px;
        color: #ffffff;
        backdrop-filter: blur(11.2778px);
        font-weight: 600;
        padding: 0.5rem;
        font-size: rem(16px);
        line-height: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .trees-text {
          padding-right: 0.3rem;
        }
        .trees-total {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 100px;
          height: 45.73px;
          background: #ffffff;
          border-radius: 5.0812px;
          color: #3dd598;

          .tree-icon {
            -webkit-mask-image: url("/images/icons/tree.svg");
            mask-image: url("/images/icons/tree.svg");
            width: 22px;
            height: 30px;
            background-color: #3dd598;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .AmountView {
    :global .amount-layout {
      height: 40vh;
      .trees-saved-container {
        width: 90%;
      }
    }
  }
}
