@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";

.baseInput {
  :global .input-container {
    display: flex;
    justify-content: space-between;
    color: $_soft_gray;
    align-items: center;
    margin: 0;
    text-align: right;
    min-width: rem(220px);
    font-size: rem(14px);
    line-height: 1.5;
    background-color: $white-color;
    border-radius: rem(8px);
    &:hover > div {
      border-color: #2995c9;
    }

    &:focus {
      border-color: #2995c9;
      outline: none;
      -webkit-box-shadow: rem(0px) rem(0px) rem(7px) rem(0px) rgba(41, 149, 201, 0.48);
      box-shadow: rem(0px) rem(0px) rem(7px) rem(0px) rgba(41, 149, 201, 0.48);
    }
    .input-tag-container {
      border-bottom: rem(1px) solid $white-border-color;
      border: rem(1px) solid $white-border-color;
      flex: 1;
      transition: all 0.3s;
      padding-right: 0.5rem;
      input {
        height: rem(40px);
        padding: rem(4px) rem(11px);
        -moz-appearance: textfield;
        width: rem(80px);
        text-align: right;
        padding-right: 0.3rem;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          // margin: 0;
        }
      }
    }
    .minus-btn,
    .plus-btn {
      cursor: pointer;
      border: rem(1px) solid $white-border-color;
      padding: 0 rem(11px);
      width: rem(42px);
      height: rem(42.229px);
      font-size: rem(24px);
      text-align: center;
      font-weight: 600;
      transition: all 0.3s;
    }
    .minus-btn {
      border-right: unset;
      border-top-left-radius: rem(8px);
      border-bottom-left-radius: rem(8px);
    }
    .plus-btn {
      border-left: unset;
      border-top-right-radius: rem(8px);
      border-bottom-right-radius: rem(8px);
    }
  }
}
