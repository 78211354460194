@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.project-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: rem(140px);
  cursor: pointer;
  margin: 1.2rem;
  padding: 0.5px;
  font-size: rem(18px);
  background-color: $gray-light-color;
  border-radius: 1rem;
  color: white;
  position: relative;
  & > div[class^='ProjectCard_module_card']{
    width: 270px !important;
  }
  :global .selected {
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: rem(4px) rem(12px);
    border-radius: 17px;
    z-index: 3;
  }
  :global .more-info {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border: 0.966527px solid #ffffff;
    backdrop-filter: blur(3px);

    padding: rem(4px) rem(12px);
    border-radius: 17px;
    z-index: 3;
  }
}

@media only screen and (max-width: 680px) {
  .project-card-container {
    :global div.selected,
    :global div.more-info {
      width: unset !important;
    }
  }
}
