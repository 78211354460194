.purchase-container {
  :global .project-purchase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    & > div {
      flex: 0 0 33.333%;
    }
    .amount {
      font-weight: 700;
      color: #1c64f2;
    }
    .project-flag {
      display: inline-block;
      margin-right: 0.5rem;
      height: 14px;
      width: 21px;
    }
  }
}
