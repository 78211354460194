@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.AmountInput {
  :global .amount-input-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    min-height: 12vh;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > p.TextComponent {
        font-size: rem(16px);
        text-align: right;
      }

      > .ButtonComponent.small {
        padding: 0;
        color: $primary-color;
        text-decoration: underline;

        &:hover {
          transform: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .AmountInput {
    :global .amount-input-container {
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      height: 15vh;
      .calculator-link {
        text-align: left;
      }
    }
  }
}
