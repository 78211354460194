@import "src/styles/_variables.scss";
.FAQContainer {
  display: flex;
  justify-content: space-around;

  :global article {
    padding: 1rem;
    min-height: 32vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 25vw;
    .question {
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 1.5rem;
      min-height: 100px;
    }
    .answer {
      color: $_soft_gray;
    }
    button {
      background-color: transparent;
    }
  }
}
@media only screen and (max-width: 680px) {
  .FAQContainer {
    flex-direction: column;
  }
}
