@import "src/styles/_tools_to_rem.scss";
@import "src/styles/_variables.scss";
.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  flex: 1;
  width: 800px;
  max-width: 90%;
  height: 643px;
  background: #ffffff;
  /* lines */

  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  :global .payment-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    width: 100%;
    padding-bottom: 30px;
    font-weight: 600;
    font-size: 15px;

    color: $dark-word-color;
    img {
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 680px) {
  .paymentContainer {
  }
}
