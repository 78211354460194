@import "src/styles/_variables.scss";
.CartResume {
  :global .card-resume-container {
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 2rem;
    background-color: white;
    min-width: 18vw;
    border-radius: 8px;
    color: $_soft_gray;
    min-height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 1rem;
    height: fit-content;
    font-size: 16px;
    top: 3.5rem;
    margin-bottom: 8rem;
    margin-top: -18rem;
    right: 5vw;
    .offset-circle {
      position: absolute;
      top: -3.5rem;
      left: 4.6vw;
      height: 180px;
      width: 180px;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
      .co2-amount {
        font-size: 32px;
        font-weight: 600;
        color: $dark-word-color;
      }
    }
    .project-container {
      display: flex;
      padding: 1rem;
      align-items: center;
      justify-content: space-between;
      img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        margin-right: 0.5rem;
        object-fit: cover;
      }
      .project-name-container {
        width: 100%;
        .project-name {
          color: $dark-word-color;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 240px;
        }
      }
    }
    .amount-container {
      display: flex;
      padding: 0.5rem 0.8rem;
      justify-content: space-between;
      .subtotal {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .discount {
          color: #008868;
          font-weight: 600;
        }
      }
    }
    .order-total {
      display: flex;
      margin: 0.3rem 0;
      justify-content: space-between;
      padding: 0.8rem;
      color: $dark-word-color;
      font-weight: 600;
      font-size: 1rem;
    }
    .trees-saved {
      background: rgba(45, 170, 120, 0.08);
      border-radius: 8px;
      height: 90px;
      position: absolute;
      bottom: -7rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      .trees-total {
        display: flex;
        align-items: center;
        margin: 0.8rem;
        margin-right: 0;
        background: #2daa78;
        border-radius: 4px;
        color: #ffffff;
        font-size: 1.4rem;
        font-weight: bold;
        height: 40px;
        padding: 0 0.5rem;
      }

      .tree-text {
        font-weight: 600;
        padding: 0.8rem;
        color: #2daa78;
        max-width: 220px;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .CartResume {
    :global .card-resume-container {
      margin-top: 5rem;
      position: relative;
      margin-left: 0;
      top: initial;
      right: initial;
      .offset-circle {
        left: 30%;
      }
    }
  }
}
